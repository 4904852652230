import SEO from "../components/SEO";
import React from "react";
import { Link, navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import upto50 from "../images/v2/psg/50%psgGrant.png";
import tick from "../images/v2/psg/tick.svg";
import topBanner from "../images/v2/psg/top-banner.png";
import PCSingapore from "../images/v2/psg/PC-singapore-1.svg";
import PCSingapore2 from "../images/v2/psg/PC-singapore-2.svg";
import DocumentIcon from "../images/v2/psg/DocumentIcon.svg";
import ComputerIcon from "../images/v2/psg/ComputerIcon.svg";
import Yellow from "../images/v2/psg/Yellow.svg";
import Pacakages from "../images/v2/psg/Pacakages.svg";
import useWidth from "../hooks/useWidth";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import Paper from "@material-ui/core/Paper";
import loadable from "@loadable/component";
import smeSupportedByLogo from "../images/v2/campaigns/sme-support-by-logo.png";
import smeInSupportOfLogo from "../images/v2/campaigns/sme-in-support-of-logo.png";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";
import WhiteButton from "../components/v2/WhightButton";
import requestDemoBGImg from "../images/v2/psg/DemoBG.svg";
import PCPsgFeature from "../images/v2/psg/psg-feature-background.png";
import IMDA_SME_Medium from "../images/v2/psg/IMDA_SME_Medium.webp";
import IMDA_SME_Vertical_Medium from "../images/v2/psg/IMDA_SME_Vertical_Medium.png";

const LazyLoad = loadable(() => import("react-lazyload"));

const glowBorder = "solid 1px #2b91ae";
const glowShadow = "0 0px 25px 0 rgb(76 143 162)";

const useStyles = makeStyles((theme) => ({
  SmeStyling: {
    width: "100%",
    margin: "20px auto",
  },
  smeFooterText: {
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      textAlign: "center",
    },
  },
  securityBannerBg: {
    background: `url(${topBanner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    // [theme.breakpoints.down("sm")]: {
    // 	backgroundSize: "contain",
    // 	backgroundPosition: "bottom",
    // 	backgroundRepeat: "no-repeat",
    // },
  },
  yellowBannerBg: {
    background: `url(${Yellow})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  requestDemoRoot: {
    background: `url(${requestDemoBGImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  "@keyframes glow": {
    "0%": {
      border: "solid 1px #e2f0ff",
      boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    },
    "20%": {
      border: glowBorder,
      boxShadow: glowShadow,
    },
    "40%": {
      border: "solid 1px #e2f0ff",
      boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    },
    "80%": {
      border: glowBorder,
      boxShadow: glowShadow,
    },
    "100%": {
      border: "solid 1px #e2f0ff",
      boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    },
  },
  contactUsCard: {
    boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    padding: "8px 24px",
    borderRadius: "0 0 10px 10px",
    border: "solid 1px #e2f0ff",
    maxWidth: 450,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.5),
    },
    // animation: "3s $glow",
    // transition: "all .5s",
    "&.glow": {
      animation: "3s $glow",
    },
  },
  psg: {
    boxShadow: "0 0 5px 0 rgb(118 130 183 / 16%)",
    borderRadius: 10,
    border: "solid 1px #e2f0ff",
    maxWidth: 450,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "32px 62px",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
    "&:hover": {
      backgroundColor: "#f1faf9",
      // boxShadow: "0 15px 50px 0 rgba(118, 130, 183, 0.16)",
    },
  },
  list: {
    "& li": {
      listStyle: "none",
      marginBottom: 10,
      fontSize: "20px",
    },
  },
  expendButton: {
    backgroundColor: "#eaf0f6",
    "&:hover": {
      background: "#a1dae2",
    },
  },
  link: {
    color: "#4592ae",
    textDecoration: "none",
    "&:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
    },
  },
  faqTitle: {
    color: "rgb(46, 63, 79)",
    display: "flex",
    fontSize: 20,
    marginTop: 10,
    fontWeight: 600,
    marginBottom: 8,
    justifyContent: "space-between",
    alignItems: "center",
  },
  faqAnswer: {
    color: "rgb(46, 63, 79)",
    fontSize: 18,
    marginTop: 8,
    maxWidth: 920,
    width: "calc( 100% - 50px )",
  },
  PCBenefits: {
    position: `relative`,
    background: `transparent url(${PCPsgFeature}) no-repeat center`,
    backgroundSize: "contain",
    backgroundPosition: "initial",
    width: "100%",
    height: "960px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "536px",
    },
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      height: "617px",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      height: "960px",
    },
  },
  PCBenefitsText: {
    width: "552px",
    fontFamily: "Poppins",
    fontSize: "40px",
    fontWeight: "600",
    textAlign: "left",
    color: "#fff",
    paddingTop: "381px",
    paddingLeft: "141px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "147px",
      paddingLeft: "12px",
      width: "100%",
      fontSize: "28px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "302px",
      paddingTop: "279px",
      paddingLeft: "23px",
      fontSize: "25px",
    },
  },
  PCBenefitsList: {
    paddingTop: "70px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "-20px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
      paddingTop: "0px",
    },
  },
  smeBannerTxthead: {
    padding: "3px",
    margin: "0",
    marginLeft: "5px",
  },
  martop: {
    marginTop: "10px",
  },
  listItemTextTitle: {
    color: "rgb(38, 41, 44)",
    fontFamily: "Poppins,sans-serif",
    fontSize: "18px",
    fontWeight: "600",
    marginTop: "0px",
    fontFamily: "Poppins, sans-serif",
    marginBottom: "8px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: "0px",
      fontSize: "12px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "0px",
      fontSize: "18px",
    },
  },
  listItemTextDescription: {
    color: "rgb(0, 0, 0)",
    paddingBottom: "20px",
    margin: "0px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "1.43",
    width: "735px",
    color: "#2e3f4f",
    [theme.breakpoints.up("xs")]: {
      paddingBottom: "30px",
      fontSize: "18px",
      width: "90%",
    },
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "0px",
      fontSize: "12px",
      width: "105%",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: "20px",
      fontSize: "16px",
    },
  },
  listStyle: {
    color: " #0698b4",
  },
  listContainer: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  requestDemoRootWidth: {
    width: "931px",
    paddingLeft: "315px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0px",
    },
  },
}));

const PSG = () => {
	const width = useWidth();
	const classes = useStyles();

	const [open, setOpen] = React.useState(0);

	const handleClickCollapse = (index) => () => {
		setOpen((res) => (res === index ? -1 : index));
	};

	const glowForm = () => {
		const form = document.getElementById("psg-form");
		const formContainer = document.getElementById("psg-form-container");
		formContainer.scrollIntoView({ behavior: "smooth", block: "start" });
		form.classList.remove("glow");
		form.classList.add("glow");
		setTimeout(() => {
			form.classList.remove("glow");
		}, 3200);
	};

	const goToPackages = () => {
		const packages = document.getElementById("packages");
		packages.scrollIntoView({ behavior: "smooth", block: "start" });
	};
	const benefitsList = [
		{
		  title: "All-in-one sales CRM",
		  description: (
			<>
			  Experience the power of Pepper Cloud CRM! Integrate with Whatsapp, Facebook Messenger, Mailchimp, MailerLite, Xero, Zoom, and more for greater efficiency.
			</>
		  ),
		},
		{
		  title: "Simple and easy-to-use",
		  description: (
			<>
			  Pepper Cloud CRM offers a simple solution for digitalising your sales operations and maximising productivity, allowing you to focus on closing deals quickly and efficiently. 

			</>
		  ),
		},
		{
		  title: "Local support and guidance",
		  description: (
			<>
			  Experience reliable 24*7 local support and guidance with Singapore’s best sales CRM software. Get all your queries answered and issues resolved in no time.
			</>
		  ),
		},
		{
		  title: "Full implementation and training",
		  description: (
			<>
			  Get comprehensive implementation and training support by Pepper Cloud to ensure a hassle-free setup of CRM and effectively leverage the software for your sales success.

			</>
		  ),
		},
		{
		  title: "Subscription or Enterprise package",
		  description: (
			<>
			  Pepper Cloud CRM offers pricing packages that can be tailored to your specific needs and budget. You can access all the CRM features and benefits you need, without incurring any extra charges.

			</>
		  ),
		},
		{
		  title: "Automatic version upgrades",
		  description: (
			<>
			  With automatic upgrades of the Pepper Cloud CRM platform, you will always have access to the latest features and improvements without any extra costs or effort.
			</>
		  ),
		},
		{
			title: "No hidden costs",
			description: (
			  <>
				Along with the PSG grant, Pepper Cloud CRM offers complete transparency and clarity in pricing. Gain everything you need to be successful in your sales efforts, without any hidden fees.
			  </>
			),
		  },
	  ];

	return (
    <div>
      <SEO
        canonical="/psg-grant"
        description="Pepper Cloud is a IMDA pre-approved CRM solution that offers up to 50% funding with PSG (Productivity Solutions Grant) designed to help SMEs boost their sales business"
        keywords="PSG grant,psg grant singapore,PSG grant application,IMDA pre-approved solution,PSG CRM,PSG pre approved vendor list,PSG application"
        pathname="/psg-grant"
        title="Singapore's #1 Sales CRM with PSG Grant up to 50% | Pepper Cloud"
      />
      <Box
        alignItems="center"
        className={classes.securityBannerBg}
        component={Grid}
        container
        justify="center"
        pb={10}
        pt={{ xs: 4, sm: 4, md: 8 }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item md={6} sm={12}>
              <HeaderTypography
                component="h1"
                fontSize={40}
                mb={4}
                mt={{ sm: 3, xs: 3 }[width]}
                textAlign={{ sm: "center", xs: "center" }[width] || "left"}
              >
                Drive your Sales Growth with up to{" "}
                <span className="highlighter">50% support from PSG</span>
              </HeaderTypography>
              <Box className={classes.SmeStyling}>
                <ParagraphTypography
                  color="text.secondary"
                  fontSize={16}
                  sx={{ padding: 0, margin: 0 }}
                  className={classes.smeFooterText}
                >
                  SMEs are eligible for up to 50% Productivity Solutions Grant
                  (PSG) support for the adoption of Pepper Cloud CRM solution, a
                  Pre-Approved Solution under the IMDA SMEs Go Digital
                  programme.
                </ParagraphTypography>
              </Box>
              <Grid alignItems="center" container spacing={2}>
                <Grid item sm={5} xs={12}>
                  <Box textAlign="center">
                    <Box
                      alt="50% PSG grant"
                      component="img"
                      height="auto"
                      maxWidth="100%"
                      src={upto50}
                    />
                  </Box>
                </Grid>
                <Grid item sm={7} xs={12}>
                  <Box display="flex">
                    <ul className={classes.list}>
                      {[
                        "Simple and easy to use",
                        "Quick implementation",
                        "1:1 Personal support",
                      ].map((each) => (
                        <ParagraphTypography
                          color="text.secondary"
                          component="h3"
                          display="flex"
                          fontSize={22}
                          key={each}
                          textAlign="center"
                        >
                          <Box
                            alt={each}
                            component="img"
                            height={26}
                            mr={2}
                            mt={0.25}
                            src={tick}
                            width={26}
                          />
                          {each}
                        </ParagraphTypography>
                      ))}
                    </ul>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Box
              component={Grid}
              id="psg-form-container"
              item
              md={6}
              sm={12}
              width={{ sm: "100%", xs: "100%" }[width]}
            >
              <Box
                alignItems="flex-end"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                {/* <Box
                  alignItems="center"
                  display={{ sm: "block", xs: "block" }[width] || "flex"}
                  justifyContent="center"
                  maxWidth={430}
                  mb={2}
                >
                  <Box
                    display="block"
                    mb={{ sm: 2, xs: 2 }[width] || 0}
                    mr={{ sm: 0, xs: 0 }[width] || 4}
                    textAlign="left"
                  >
                    <ParagraphTypography
                      color="#2e3f4f"
                      fontSize={13}
                      fontWeight={700}
                      letterSpacing="-.5px"
                      mb={1}
                      mt={0}
                    >
                      Supported by:
                    </ParagraphTypography>
                    <Box
                      alt="IMDA + Pepper Cloud + Go Digital"
                      component={"img"}
                      height={{ sm: 23, xs: 23 }[width] || 26}
                      maxWidth="100%"
                      src={smeSupportedByLogo}
                    />
                  </Box>
                  <Box display="block" textAlign="left">
                    <ParagraphTypography
                      color="#2e3f4f"
                      fontSize={13}
                      fontWeight={700}
                      letterSpacing="-.5px"
                      mb={1}
                      mt={0}
                    >
                      In support of:
                    </ParagraphTypography>
                    <Box
                      alt="SG Digital + Pepper Cloud"
                      component={"img"}
                      height={{ sm: 38, xs: 38 }[width] || 40}
                      maxWidth="100%"
                      src={IMDA_SME_Small}
                    />
                  </Box>
                </Box> */}
                {/* <Box
                  onClick={() =>
                    window.open(
                      "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                    )
                  }
                  sx={{ cursor: "pointer" }}
                  component={"img"}
                  src={IMDA_SME_Medium}
                  alt={"IMDA Pre-approved Solution Provider"}
                  maxWidth={"450px"}
                  margin={0}
                  width={"100%"}
                  mb={0}
                /> */}
                <Box
                  sx={{
                    backgroundColor: "#2e3f4f",
                    borderRadius: "10px 10px 0 0",
                  }}
                  width={"100%"}
                  maxWidth={"450px"}
                >
                  <HeaderTypography
                    component="h5"
                    fontSize={28}
                    mb={0}
                    color={"white"}
                    margin={0}
                    padding={1}
                    width={"100%"}
                    textAlign={
                      { sm: "center", xs: "center" }[width] || "center"
                    }
                  >
                    Avail up to <span className="highlighter2">50%</span> PSG
                    grant
                  </HeaderTypography>
                </Box>
                <Paper className={classes.contactUsCard} id="psg-form">
                  <LazyLoad offset={100} once>
                    <Box height={535}>
                      <Box
                        border="none"
                        component="iframe"
                        height="100%"
                        overflow="hidden"
                        src="https://app.peppercloud.com/form/a812ffea-9ed4-4946-b5e0-67052acf3e97/embed"
                        title="Contact Us"
                        width="99%"
                      />
                    </Box>
                  </LazyLoad>
                </Paper>
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>

      <Grid container xs={12} className={classes.listContainer}>
        <Grid item xs={12} sm={5}>
          <Box className={classes.PCBenefits}>
            <Box className={classes.PCBenefitsText}>
              Here is why <br /> Pepper Cloud is Singapore’s Best sales CRM
              Software
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} width="100%">
          <Box className={classes.PCBenefitsList}>
            <ul className={classes.listStyle}>
              {benefitsList.map((item, index) => {
                return (
                  <li>
                    <Box className={classes.listItemTextTitle}>
                      {item.title}
                    </Box>
                    <Box className={classes.listItemTextDescription}>
                      {item.description}
                    </Box>
                  </li>
                );
              })}
            </ul>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      <Container>
        <HeaderTypography component="h2" fontSize={40} textAlign="center">
          PSG grant is available for <br /> all Singapore SMEs
        </HeaderTypography>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          margin="auto"
          maxWidth={1100}
        >
          <Grid container spacing={4}>
            <Grid item md={6} sm={12}>
              <Box
                onClick={() =>
                  window.open(
                    "https://services2.imda.gov.sg/CTOaaS/DigitalHealthCheck?utm_source=PA+vendor&utm_medium=vendor+site&utm_campaign=SN222"
                  )
                }
                sx={{ cursor: "pointer" }}
                component={"img"}
                src={IMDA_SME_Vertical_Medium}
                alt={"IMDA Pre-approved Solution Provider"}
                margin={0}
                height={"100%"}
                width={"100%"}
                mb={0}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                width={"100%"}
                style={{ gap: "20px" }}
              >
                <Paper
                  alignItems="center"
                  style={{ padding: "30px 50px" }}
                  className={classes.psg}
                  component={"a"}
                  href="/Pepper-Cloud-CRM-PSG-Package.pdf"
                  rel="nofollow"
                  target="_blank"
                  onClick={goToPackages}
                >
                  <Box
                    alt="PSG application"
                    component="img"
                    height={65}
                    m={0}
                    src={DocumentIcon}
                    width="auto"
                  />
                  <ParagraphTypography
                    color="text.secondary"
                    fontSize={20}
                    textAlign="center"
                  >
                    Choose your package from IMDA pre-approved solutions and get
                    a quotation from us.
                  </ParagraphTypography>
                </Paper>

                <Paper
                  alignItems="center"
                  className={classes.psg}
                  component={"a"}
                  style={{ padding: "30px 50px" }}
                  href="https://www.businessgrants.gov.sg/"
                  rel="nofollow"
                  target="_blank"
                >
                  <Box
                    alt="Business Grant Portal"
                    component="img"
                    m={0}
                    height={65}
                    src={ComputerIcon}
                    width="auto"
                  />
                  <ParagraphTypography
                    color="text.secondary"
                    fontSize={20}
                    textAlign="center"
                    padding={0}
                  >
                    Submit PSG application on{" "}
                    <span className={classes.link}>
                      Business Grant Portal (BGP)
                    </span>
                    . Our friendly staff can assist you further.
                  </ParagraphTypography>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Box mt={0} mb={4}>
            <Button
              color="secondary"
              onClick={glowForm}
              size="large"
              variant="contained"
            >
              Apply for PSG grant now
            </Button>
          </Box>
        </Box>
      </Container>

      <Divider />
      <Box
        alignItems="center"
        className={classes.yellowBannerBg}
        component={Grid}
        container
        justify="center"
        pb={10}
        pt={8}
      >
        <Container id="packages">
          <HeaderTypography component="h2" fontSize={40} textAlign="center">
            Based on your business, scale, and requirements,
            <br /> we offer 4 pre-approved PSG packages
          </HeaderTypography>
          <Box mb={4} pt={4} textAlign="center">
            <Box
              alt="PSG grant packages"
              component="img"
              height="auto"
              maxWidth="100%"
              src={Pacakages}
            />
          </Box>
          <ParagraphTypography
            color="text.secondary"
            fontSize={22}
            textAlign="center"
          >
            *For custom solutions not covered under PSG, please email us at{" "}
            <a className={classes.link} href="mailto:sales@peppercloud.com">
              sales@peppercloud.com
            </a>
          </ParagraphTypography>
          <Box
            mb={{ xs: 2, sm: 2, md: 8 }}
            mt={{ xs: 2, sm: 2, md: 6 }}
            textAlign="center"
          >
            <Button
              color="secondary"
              href="/Pepper-Cloud-CRM-PSG-Package.pdf"
              size="large"
              target="_blank"
              variant="contained"
            >
              Choose Your Package
            </Button>
          </Box>
        </Container>
      </Box>
      <Divider />
      <Container>
        <HeaderTypography component="h2" fontSize={40} textAlign="center">
          PSG Grant - Frequently asked questions
        </HeaderTypography>
        {[
          {
            title: "What is PSG?",
            ans: (
              <>
                Productivity Solutions Grant (PSG), a joint initiative by the{" "}
                <a href="https://www.imda.gov.sg/">
                  Infocomm Media Development Authority
                </a>{" "}
                (IMDA) and{" "}
                <a href="https://www.enterprisesg.gov.sg/">
                  {" "}
                  Enterprise Singapore
                </a>
                , supports companies that are keen on adopting IT solutions such
                as CRM, to enhance their business processes. For more
                information about PSG, read our{" "}
                <a href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/">
                  {" "}
                  guide to PSG
                </a>
                .
              </>
            ),
          },
          {
            title: "Is your company eligible?",
            ans: (
              <React.Fragment>
                PSG is available to
                <ul>
                  <li>Singapore-registered or incorporated businesses.</li>
                  <li>Companies with a minimum of 30% local shareholding.</li>
                  <li>
                    Companies with group annual sales turnover lesser than S$100
                    million, or the employment size less than 200 workers.
                  </li>
                  <li>
                    Companies must use the subscribed IT Solution in Singapore.
                  </li>
                </ul>
              </React.Fragment>
            ),
          },
          {
            title: "How many PSG grants a business entity can apply for?",
            ans: "Companies can apply for more than one PSG grant depending on the business needs. However, support under PSG is for one package per solution category per deployment location.",
          },
          {
            title: "Can a company submit concurrent applications?",
            ans: "Yes, you can submit concurrent applications.",
          },
          {
            title: "What is the annual grant cap for PSG?",
            ans: (
              <>
                Each company supported by{" "}
                <a href="https://www.enterprisesg.gov.sg/">ESG </a>is subject to
                an annual grant cap of S$ 30,000 for supported solutions. This
                starts on 1st April and ends on 31 March the following year.
              </>
            ),
          },
          {
            title: "What is the processing time for applications?",
            ans: "After submission of the required information, PSG grant applications are processed within 4-6 weeks.",
          },
          {
            title: "What is the processing time for claims disbursement?",
            ans: "After submission of the required information, claims are processed within 4-6 weeks.",
          },
          {
            title: "How will the PSG disbursement take place?",
            ans: "PSG approved amount will be credited directly to the company’s designated bank account through GIRO.",
          },
          {
            title:
              "Can we purchase the solution or start work before filing for PSG Grant?",
            ans: "No. Your grant application will be rejected if you have signed the quote, made payment to a vendor or have started any work on the project before you file for the grant.",
          },
          {
            title:
              "Do we need to wait for grant approval before we start work on the project?",
            ans: (
              <>
                No. If you wish, you can sign a quote, make the advance payment
                to the{" "}
                <a href="https://peppercloud.com/">PSG pre-approved vendor</a>,
                and start work after you have submitted your grant application.
                However, you run the risk in case the grant is not approved for
                any reason.
              </>
            ),
          },
        ].map((each, index) => (
          <div key={each.title}>
            <Box
              className={classes.faqTitle}
              m={0}
              onClick={handleClickCollapse(index)}
            >
              <Box component="h3" fontSize={18} my={2}>
                {each.title}
              </Box>
              <div>
                <IconButton
                  aria-label="Expand or Collapse"
                  className={classes.expendButton}
                  color="default"
                >
                  {index !== open ? (
                    <AddIcon fontSize="small" />
                  ) : (
                    <RemoveIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
            </Box>
            <Collapse in={open === index} timeout="auto" unmountOnExit>
              <p className={classes.faqAnswer} fontSize={16}>
                {each.ans}
              </p>
            </Collapse>
            <Divider />
          </div>
        ))}
      </Container>
      <ParagraphTypography
        color="text.secondary"
        fontSize={18}
        textAlign="center"
      >
        For detailed information about PSG, read our
        <a
          className={classes.link}
          href="https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/"
        >
          &nbsp;ultimate guide to PSG grant
        </a>
      </ParagraphTypography>
      <Box className={classes.requestDemoRoot} mt={6} py={8}>
        <Container>
          <HeaderTypography
            color="common.white"
            component="h2"
            fontSize={38}
            mb={0}
            mt={0}
            textAlign="center"
            className={classes.requestDemoRootWidth}
          >
            Avail your PSG grant today and get Singapore’s #1 sales CRM
          </HeaderTypography>
          <Box display="flex" justifyContent="center" mt={6}>
            <WhiteButton
              color="primary"
              onClick={glowForm}
              size="large"
              variant="contained"
            >
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Book a Free Demo
            </WhiteButton>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default PSG;
